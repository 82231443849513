import React, { useState } from 'react'
import '../css/Work.css'
import Job from './Job'
import jobs from '../jobs'
import JobButton from './JobButton'

function Work() {
  const [displayNumber, setDisplayNumber] = useState(0)

  return (
    <div className="container" id="work-section">
      <div className="section-title">
        <p>
          <span className="section-title-number">02.</span>
          <span className="section-title-body">Where I've Worked</span>
        </p>
        <div className="horizontal-line"></div>
      </div>

      <div className="work-div">
        <div className="work-names">
          {jobs.map((job) => (
            <JobButton
              key={job.id}
              job={job}
              displayNumber={displayNumber}
              setDisplayNumber={setDisplayNumber}
            />
          ))}
        </div>
        <div className="work-body">
          <Job job={jobs[displayNumber]} />
        </div>
      </div>
    </div>
  )
}

export default Work
