import React from 'react'
import '../css/Socials.css'
import {
  FiInstagram,
  FiGithub,
  FiTwitter,
  FiLinkedin,
  FiCodepen,
} from 'react-icons/fi'

function Socials() {
  return (
    <div className="socials-div">
      <a
        href="https://www.instagram.com/amadywr/?hl=en"
        target="_blank"
        rel="noreferrer"
        className="socials-links"
      >
        <FiInstagram size={20} className="socials-icons" />
      </a>
      <a
        href="https://github.com/amadywr"
        target="_blank"
        rel="noreferrer"
        className="socials-links"
      >
        <FiGithub size={20} className="socials-icons" />
      </a>
      <a
        href="https://twitter.com/amadywr"
        target="_blank"
        rel="noreferrer"
        className="socials-links"
      >
        <FiTwitter size={20} className="socials-icons" />
      </a>
      <a
        href="https://www.linkedin.com/in/amadywr/"
        target="_blank"
        rel="noreferrer"
        className="socials-links"
      >
        <FiLinkedin size={20} className="socials-icons" />
      </a>
      <a
        href="https://codepen.io/amadywr"
        target="_blank"
        rel="noreferrer"
        className="socials-links"
      >
        <FiCodepen size={20} className="socials-icons" />
      </a>
      <div className="vertical-line"></div>
    </div>
  )
}

export default Socials
