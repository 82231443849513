import React from 'react'
import '../css/Home.css'
import '../css/Query.css'

function Home() {
  return (
    <div className="container" id="home-section">
      <div className="home">
        <p className="hi">Hi, my name is</p>
        <p className="name">Ahmad Yawari.</p>
        <p className="role">I build things for the web.</p>
      </div>
    </div>
  )
}

export default Home
