import "./css/App.css"
import "./css/Query.css"
import Header from "./components/Header"
import Home from "./components/Home"
import Socials from "./components/Socials"
import Email from "./components/Email"
import About from "./components/About"
import Work from "./components/Work"
import Projects from "./components/Projects"
import Contact from "./components/Contact"
import Footer from "./components/Footer"

function App() {
  return (
    <div className="App">
      <Header />
      <Socials />
      <Email />
      <Home />
      <About />
      <Work />
      <Projects />
      <Contact />
      <Footer />
    </div>
  )
}

export default App
