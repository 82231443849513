import React, { useState } from 'react'
import '../css/Header.css'
import logo from '../img/logo.png'
import resume from '../img/Resume.pdf'

function Header() {
  const [showMenu, setShowMenu] = useState(false)

  function toggleButton() {
    setShowMenu(!showMenu)
  }

  return (
    <header>
      <a href='#home-section' className='logo'>
        <img src={logo} alt='logo' />
      </a>

      <div className='toggle-button' onClick={() => toggleButton()}>
        <span className={showMenu ? 'bar bar1 active' : 'bar bar1'}></span>

        <span className={showMenu ? 'bar bar2 active' : 'bar bar2'}></span>

        <span className={showMenu ? 'bar bar3 active' : 'bar bar3'}></span>
      </div>

      <nav>
        <ul className={showMenu ? 'nav-menu active' : 'nav-menu'}>
          <li>
            <a
              href='#about-section'
              className='nav-item'
              onClick={() => toggleButton()}
            >
              <span className='nav-item-number'>01.</span>
              <span className='nav-item-text anchor-effect'>About</span>
            </a>
          </li>
          <li>
            <a
              href='#work-section'
              className='nav-item'
              onClick={() => toggleButton()}
            >
              <span className='nav-item-number'>02.</span>
              <span className='nav-item-text anchor-effect'>Experience</span>
            </a>
          </li>
          <li>
            <a
              href='#projects-section'
              className='nav-item'
              onClick={() => toggleButton()}
            >
              <span className='nav-item-number'>03.</span>
              <span className='nav-item-text anchor-effect'>Work</span>
            </a>
          </li>
          <li>
            <a
              href='#contact-section'
              className='nav-item'
              onClick={() => toggleButton()}
            >
              <span className='nav-item-number'>04.</span>
              <span className='nav-item-text anchor-effect'>Contact</span>
            </a>
          </li>
          <li>
            <a
              href={resume}
              target='_blank'
              rel='noreferrer'
              className='nav-item primary-btn resume-btn'
            >
              Resume
            </a>
          </li>
        </ul>
      </nav>
    </header>
  )
}

export default Header
