import React from 'react';
import '../css/Contact.css';

function Contact() {
  return (
    <div className="container" id="contact-section">
      <p className="contact-heading">
        <span className="contact-heading-number">04.</span>
        <span className="contact-heading-text">What's next?</span>
      </p>
      <p className="contact-GIT">Get In Touch</p>
      <p className="contact-text">
        Connect with me to explore collaborative opportunities or discuss your
        next project. Whether it's web development, UI/UX design, or innovative
        solutions, let's bring your ideas to life together. Reach out and let's
        create something amazing
      </p>
      <a href="mailto:k_han21@yahoo.com" className="primary-btn say-hello">
        Contact
      </a>
    </div>
  );
}

export default Contact;
