import React from 'react'
import '../css/Footer.css'

function Footer() {
  return (
    <div className="container" id="footer-section">
{/*       <p>
        Designed by{' '}
        <a href="https://github.com/bchiang7" target="_blank" rel="noreferrer">
          Brittany Chiang
        </a>
      </p>
      <p>
        Built by{' '}
        <a href="https://github.com/amadywr" target="_blank" rel="noreferrer">
          Ahmad Yawari
        </a>
      </p> */}
    </div>
  )
}

export default Footer
