import React from 'react';
import '../css/Projects.css';
import openroad from '../img/OpenRoad2.png';
import courseRecommender from '../img/Course-Recommender.png';
import githubFinder from '../img/github-finder.png';

import { FiExternalLink, FiGithub } from 'react-icons/fi';

function Projects() {
  return (
    <div className="container" id="projects-section">
      <div className="section-title">
        <p>
          <span className="section-title-number">03.</span>
          <span className="section-title-body">Somethings I've Built</span>
        </p>
        <div className="horizontal-line"></div>
      </div>

      <div className="projects-div">
        <div className="each-project project1">
          {/* <a
            target="_blank"
            rel="noreferrer"
            href="https://xd.adobe.com/view/94d27d12-f34c-44e2-a3e8-17c88e3e41c2-c181/"
            className="project-img-anchor"
          > */}
          <img
            src={openroad}
            alt="project screenshot"
            className="project-img-odd"
          />
          {/* </a> */}

          <div className="project-info project-info-odd">
            <div className="project-head project-head-odd">
              <p className="featured-project">Featured Project</p>
              {/* <a
                href="https://xd.adobe.com/view/94d27d12-f34c-44e2-a3e8-17c88e3e41c2-c181/"
                className="project-name"
                target="_blank"
                rel="noreferrer"
              > */}
              <p className="project-name">OpenRoad</p>
              {/* </a> */}
            </div>
            <p className="project-description project-description-odd">
              Group based machine learning project which can predict traffic
              congestions on road networks across Sydney with Google Maps API
              integration. Developed with Python, Flask, Scikit-Learn,
              Typescript & React.js.
            </p>
            <ul>
              <li>React-JS</li>
              <li>TypeScript</li>
              <li>Python</li>
              <li>Tensorflow</li>
            </ul>
            <div>
              <a
                href="https://github.com/RheshavMaharaj/traffic-management"
                target="_blank"
                rel="noreferrer"
              >
                <FiGithub
                  size={20}
                  className="project-socials-icons project-socials-icons-odd"
                />
              </a>
              {/* <a
                href="https://xd.adobe.com/view/94d27d12-f34c-44e2-a3e8-17c88e3e41c2-c181/"
                target="_blank"
                rel="noreferrer"
              >
                <FiExternalLink
                  size={20}
                  className="project-socials-icons project-socials-icons-odd"
                />
              </a> */}
            </div>
          </div>
        </div>

        <div className="each-project project2">
          <a
            href="https://grand-dasik-c1e3f6.netlify.app/"
            className="project-img-anchor project-img-anchor-even"
            target="_blank"
            rel="noreferrer"
          >
            <img
              src={githubFinder}
              alt="project screenshot"
              className="project-img-even"
            />
          </a>

          <div className="project-info project-info-even">
            <div className="project-head project-head-even">
              <p className="featured-project">Featured Project</p>
              <a
                href="https://grand-dasik-c1e3f6.netlify.app/"
                className="project-name"
                target="_blank"
                rel="noreferrer"
              >
                Github Finder
              </a>
            </div>
            <p className="project-description project-description-even">
              User friendly web app for exploring GitHub profiles and
              repositories effortlessly. Search users, view profiles with key
              details, and delve into comprehensive repository information,
              including demos.
            </p>
            <ul>
              <li>React-JS</li>
              <li>JavaScript</li>
            </ul>
            <div>
              <a
                href="https://github.com/amadywr/React-Task-Tracker"
                target="_blank"
                rel="noreferrer"
              >
                <FiGithub
                  size={20}
                  className="project-socials-icons project-socials-icons-even"
                />
              </a>
              <a
                href="https://grand-dasik-c1e3f6.netlify.app/"
                target="_blank"
                rel="noreferrer"
              >
                <FiExternalLink
                  size={20}
                  className="project-socials-icons project-socials-icons-even"
                />
              </a>
            </div>
          </div>
        </div>

        <div className="each-project project3 last-project">
          <a
            href="https://course-management-xqw3.onrender.com/"
            className="project-img-anchor"
            target="_blank"
            rel="noreferrer"
          >
            <img
              src={courseRecommender}
              alt="project screenshot"
              className="project-img-odd"
            />
          </a>

          <div className="project-info project-info-odd">
            <div className="project-head project-head-odd">
              <p className="featured-project">Featured Project</p>
              <a
                href="https://course-management-xqw3.onrender.com"
                className="project-name"
                target="_blank"
                rel="noreferrer"
              >
                Course Management
              </a>
            </div>
            <p className="project-description project-description-odd">
              A web app for university course coordinators to manage courses and
              give new students course recommendations based on their
              preferences. The application allows course coordinators to create
              an account in order to create, read, update and delete course.
            </p>
            <ul>
              <li>HTML</li>
              <li>CSS</li>
              <li>JavaScript</li>
              <li>EJS</li>
              <li>Express-JS</li>
              <li>MongoDB</li>
            </ul>
            <div>
              <a
                href="https://github.com/amadywr/SDS5-V2"
                target="_blank"
                rel="noreferrer"
              >
                <FiGithub
                  size={20}
                  className="project-socials-icons project-socials-icons-odd"
                />
              </a>
              <a
                href="https://course-management-xqw3.onrender.com/"
                target="_blank"
                rel="noreferrer"
              >
                <FiExternalLink
                  size={20}
                  className="project-socials-icons project-socials-icons-odd"
                />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Projects;
