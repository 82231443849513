import React from 'react'
import '../css/Email.css'

function Email() {
  return (
    <div className="email-div">
      <a href="mailto:k_han21@yahoo.com">k_han21@yahoo.com</a>

      <div className="vertical-line"></div>
    </div>
  )
}

export default Email
