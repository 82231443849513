import React from 'react'

function JobButton({ job, displayNumber, setDisplayNumber }) {
  return (
    <button
      className="work-button"
      onClick={() => {
        setDisplayNumber(job.id)
      }}
      style={{
        color: displayNumber === job.id ? '#64ffda' : '#8892b0',
        borderColor: displayNumber === job.id ? '#64ffda' : '#8892b0',
      }}
    >
      {job.place}
    </button>
  )
}

export default JobButton
